.agent-page {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}
.nav-wrap {
  position: absolute;
  width: 100%;
  height: 2.75rem;
  padding: 0.625rem 0.75rem;
  box-sizing: border-box;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}
.goback-wrap {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.goback-arrow {
  width: 1.5rem;
  height: 1.5rem;
  color: white;
}
.nav-title {
  color: #FFF;
  text-align: center;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.3px;
  text-transform: capitalize;
  margin: 0;
  padding: 0;
  position: relative;
  left: 6.15625rem;
}
.agent-bg {
  width: 100%;
  display: block;
}
.input-wrap {
  position: absolute;
  top: 24.5rem;
  left: 1.6875rem;
  width: 20.125rem;
  height: 3rem;
  padding: 0 1.6875rem 0 1.146rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}
.code {
  display: flex;
  flex-direction: row;
  width: 12.625rem;
  height: 2.375rem;
  padding-left: 1.375rem;
  align-items: center;
  gap: 0.375rem;
  color: white;
  font-size: 1.5rem;
  font-weight: 700;
}
.copy {
  position: relative;
  top: 0.2rem;
  width: 5rem;
  height: 2.25rem;
}
